import { sendLog } from "@otto-ec/global-resources/debug";
import { generateRandomString, moduleLogger } from "./util";
import { type DataContainer, type Feature, submitMerge } from "@otto-ec/tracking-bct";

const attributeNameId = "id";
const attributeNameBenefitId = "benefit-id";
const attributeNameFeature = "feature";
const attributeNameTeam = "team";
const attributeNameVariationId = "variation-id";
const attributeNameTargetContextDreson = "target-context-dreson";
const attributeNameCurrentContextDreson = "current-context-dreson";
const shouldSendTracking = "send-tracking";
const attributeNameTrackingParentId = "tracking-parent-id";
const attributeNameSendEvents = "send-events";
const attributeNameRemoveExpired = "remove-expired";
const shouldAddFadeInAnimation = "add-fade-in-animation";

const log = moduleLogger.scope("tag");

/*                                                                                                   */
/*                                                                              */
const MAX_CLEANUP_PERIOD_IN_MILLIS = 20 * 24 * 60 * 60 * 1000;

const MIN_RATIO_TO_FADE_IN = 0.8;

export class BenefitTagElement extends HTMLElement {
	static observedAttributes = [
		attributeNameId,
		attributeNameBenefitId,
		attributeNameFeature,
		attributeNameTeam,
		attributeNameVariationId,
		attributeNameTargetContextDreson,
		attributeNameCurrentContextDreson,
		shouldSendTracking,
		attributeNameTrackingParentId,
		attributeNameSendEvents,
		attributeNameRemoveExpired,
		shouldAddFadeInAnimation,
	];
	private _isConnected = false;

	override get isConnected() {
		return this._isConnected;
	}

	override set isConnected(value: boolean) {
		this._isConnected = value;
	}

	constructor() {
		super(); /*                                     */
		this.isConnected = false;
	}

	async attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		log.info("Attribute update: ", name, oldValue, newValue, this.isConnected);
		if (this.isConnected) {
			await this.callBackend();
		}
	}

	async connectedCallback() {
		this.isConnected = true;
		await this.callBackend();
	}

	async callBackend() {
		const id = this.getAttribute(attributeNameId);
		const urlSearchParams = this.getSearchParams();

		log.info("Calling tag for %s with %s", id, JSON.stringify(urlSearchParams));

		let tries = 0;
		let success = false;
		const status_code = [];

		while (tries++ < 3 && !success) {
			let response: Response;
			try {
				response = await fetch(`/benefit-tag/tag?${urlSearchParams}`);
			} catch (error) {
				log.warn("tag request returns an error!", error);

				if (error instanceof DOMException) {
					status_code.push(error.name);
				} else {
					status_code.push(error);
				}
				continue;
			}

			status_code.push(response.status);
			success = response.ok;

			if (success) {
				/*                          */
				response.text().then((text) => {
					this.innerHTML = text;
					initTag(this);
				});
				break;
			}
			log.warn("tag endpoint returns a non ok response!", response.status);
		}

		if (!success) {
			sendLog("ft9-benefit-tag-not-ok", {
				urlSearchParams: urlSearchParams.toString(),
				status_code: status_code,
				tries: tries - 1,
			});
		}
	}

	private getSearchParams() {
		const urlSearchParams = new URLSearchParams({});

		function addToSearchParam(parmName: string, value: string | null) {
			if (value != null) {
				urlSearchParams.append(parmName, value);
			}
		}

		addToSearchParam("benefitId", this.getAttribute(attributeNameBenefitId));
		addToSearchParam("feature", this.getAttribute(attributeNameFeature));
		addToSearchParam("team", this.getAttribute(attributeNameTeam));
		addToSearchParam("variationId", this.getAttribute(attributeNameVariationId));
		addToSearchParam("targetContextDreson", this.getAttribute(attributeNameTargetContextDreson));
		addToSearchParam("currentContextDreson", this.getAttribute(attributeNameCurrentContextDreson));
		return urlSearchParams;
	}
}

function initTag(initializingWrapper: BenefitTagElement) {
	const tag = initializingWrapper.firstElementChild;
	if (!tag || !(tag instanceof HTMLElement)) {
		return;
	}

	if (initializingWrapper.hasAttribute(attributeNameRemoveExpired) && tag.dataset.expirationDate) {
		const expirationDate = new Date(tag.dataset.expirationDate);

		const delayInMillis: number = Math.min(Math.max(expirationDate.getTime() - new Date().getTime(), 1), MAX_CLEANUP_PERIOD_IN_MILLIS);
		window.setTimeout(() => {
			tag.remove();
		}, delayInMillis);
	}

	sendLoadedTracking(initializingWrapper, tag);

	setUpFadeIn(initializingWrapper);
}

function sendLoadedTracking(initializingWrapper: BenefitTagElement, tag: HTMLElement) {
	if (!initializingWrapper.hasAttribute(shouldSendTracking)) {
		return;
	}

	const trackingData = tag.dataset.trackingLabels;
	if (!trackingData) {
		return;
	}

	let feature = {
		id: generateRandomString(20),
		name: "Benefit",
		status: "loaded",
		labels: JSON.parse(trackingData) as DataContainer,
	} as Feature;

	const trackingParentId = initializingWrapper.getAttribute(attributeNameTrackingParentId);
	if (trackingParentId) {
		feature = {
			...feature,
			parentId: trackingParentId,
		};
	}

	submitMerge({}, [feature]);
}

function setUpFadeIn(initializingWrapper: BenefitTagElement) {
	if (!initializingWrapper.hasAttribute(shouldAddFadeInAnimation)) {
		return;
	}

	const options: IntersectionObserverInit = {
		threshold: MIN_RATIO_TO_FADE_IN,
	};

	const observer = new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
		for (const entry of entries) {
			if (entry.intersectionRatio >= MIN_RATIO_TO_FADE_IN) {
				entry.target.classList.add("benefit-tag__visible");

				observer.unobserve(entry.target);
			}
		}
	}, options);
	observer.observe(initializingWrapper);
}

customElements.define("ft9-benefit-tag-v1", BenefitTagElement);
